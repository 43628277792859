<template>
  <div class="information_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back"></div>
      <div class="title">我的消息</div>
      <div class="rightBtn"></div>
    </div>
    <div class="header_menu">
      <router-link class="menu_item" to="/announcement">
        <svg-icon icon-class="system" />
        <h2>公告</h2>
      </router-link>
      <router-link class="menu_item" to="/incomeDetails">
        <svg-icon icon-class="income" />
        <h2>收益</h2>
      </router-link>
      <router-link class="menu_item" to="/dynamicList">
        <div class="menu_item_box">
          <svg-icon icon-class="dynamic" />
          <div class="unreadMsg" v-if="unreadMessages.dtCount">{{ unreadMessages.dtCount > 99 ? '99+' : unreadMessages.dtCount }}</div>
        </div>
        <h2>动态</h2>
      </router-link>
      <router-link class="menu_item" to="/addUser">
        <svg-icon icon-class="addUser" />
        <h2>添加</h2>
      </router-link>
    </div>
    <!-- 消息-->
    <div class="msg_content">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        :advList="advList"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :isHigehtMax="true"
        :error="error"
      >
        <!-- <AdvSwiper  v-if="advList.length" :hasPagination="false" class="adv"/> -->
        <van-swipe-cell v-for="item in list" :key="item.id" right-width="90">
          <div class="msg_item" @click="goMsgDetail(item)">
            <div class="avatar">
              <ImgDecypt :src="item.userAvatar" round />
            </div>
            <div class="msg_box">
              <div class="msg_info">
                <div class="name_box">
                  <h2 class="ellipsis">{{ item.userName }}</h2>
                </div>
                <div class="msg_text ellipsis">{{ item.preContent }}</div>
              </div>
            </div>
            <div class="right_box">
              <div class="time">{{ item.createdAt | dateago }}</div>
              <div class="noReadMsg" v-if="item.noReadNum > 0">{{ item.noReadNum }}</div>
              <div class="noMsg" v-else></div>
            </div>
          </div>
          <template #right>
            <van-button class="delBtn" square type="danger" text="删除" @click="delMsg(item)" />
          </template>
        </van-swipe-cell>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import { SwipeCell, Button, Toast } from 'vant';
import { noRedMessage } from '@/api/app';
import ImgDecypt from '@/components/ImgDecypt';
import { mapGetters } from 'vuex';
import { getSessionList, delMessage } from '@/api/information';
// import AdvSwiper from "@/components/AdvSwiper";
import PullRefresh from '@/components/PullRefresh';
import { AdType, getAdItem } from '@/utils/getConfig';

export default {
  name: 'information',
  components: {
    ImgDecypt,
    PullRefresh,
    // AdvSwiper: AdvSwiper,
    [Button.name]: Button,
    [SwipeCell.name]: SwipeCell,
  },
  computed: {
    ...mapGetters({
      unreadMessages: 'unreadMessages',
    }),
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      list: [],
      advList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  watch: {
    $route(to, from) {
      if ((from.path == '/dynamicList' && to.path == '/information') || (from.path == 'msgDetails' && to.path == '/information')) {
        this.noRedMessage();
      }
    },
  },
  created() {
    this.advList = getAdItem(AdType.MESSAGE_MODULE);
    this.getList('refresh');
  },
  activated() {
    this.getList('refresh');
  },
  methods: {
    goMsgDetail(item) {
      let encode = window.encodeURI(
        JSON.stringify({
          noReadNum: item.noReadNum,
          userName: item.userName,
          userAvatar: item.userAvatar,
          sessionId: item.sessionId,
          takeUid: item.takeUid,
          sendUid: item.sendUid,
        }),
      );
      let data = {
        path: '/msgDetails',
        query: {
          data: window.btoa(encode),
        },
      };
      this.$router.push(data);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.loading = true;
      this.finished = false;
      this.getList('refresh');
    },
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(getSessionList, req);
        this.refreshing = false;
        this.loading = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.finished = true;
      }
    },
    async delMsg(item) {
      let res = await this.$Api(delMessage, { sessionId: item.sessionId });
      if (res && res.code == 200) {
        this.pageNumber = 1;
        this.getList('refresh');
        Toast('删除成功');
      } else {
        Toast(res.tip || '删除失败');
      }
    },
    async noRedMessage() {
      let res = await this.$Api(noRedMessage);
      if (res && res.code == 200) {
        this.$store.commit('user/SET_UNREADMSG', res.data);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.information_warp {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid rgb(217, 217, 217);
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 8px;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .header_menu {
    padding: 12px 20px 0;
    height: 88px;
    display: flex;
    background-color: #fff;
    .menu_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      .menu_item_box {
        position: relative;
        > svg {
          font-size: 46px;
        }
        .unreadMsg {
          position: absolute;
          top: -5px;
          right: -3px;
          font-size: 14px;
          height: 20px;
          width: 20px;
          line-height: 20px;
          padding: 0 6px;
          box-sizing: border-box;
          color: #fff;
          background: #f65c63;
          border-radius: 50%;
          text-align: center;
        }
      }

      > svg {
        font-size: 46px;
      }

      h2 {
        padding-top: 5px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .msg_content {
    padding: 11px 0px 0;
    height: calc(100% - 80px);
    box-sizing: border-box;
    // .adv {
    // margin: 0 0 20px;
    /deep/ .swiper-container {
      height: 73px;
    }
    /deep/ .adv-swiper-slide {
      height: 73px !important;
    }
    /deep/ .swiperSlide {
      background: #00000000;
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
      border-radius: 10px;
    }
    // }
    /deep/ .van-pull-refresh {
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    .msg_item {
      display: flex;
      align-items: center;
      background-color: #fff;
      margin: 3px 0;
      padding: 14px 22px;
      box-sizing: border-box;
      width: 100%;
      .avatar {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        position: relative;

        .origin {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #ff5c5b;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          // border: 3px solid #151515;
        }
      }
      .msg_box {
        padding-left: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        .msg_info {
          .name_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            h2 {
              font-size: 16px;
            }

            span {
              padding-left: 5px;
              color: #4e586e;
              flex-shrink: 0;
            }
          }

          .msg_text {
            width: 200px;
            padding-top: 4px;
            font-size: 11px;
            color: rgb(153, 153, 153);
          }
        }

        .icon {
          > svg {
            font-size: 30px;
          }
        }
      }
      .right_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .time {
          font-size: 11px;
          color: rgb(92, 113, 132);
          margin-bottom: 5px;
        }
        .noReadMsg {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: rgb(255, 103, 143);
          font-size: 11px;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }
        .noMsg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  .delBtn {
    width: 90px;
    height: 100%;
  }
}
</style>
